<template>
  <!-- 挂号取消 -->
  <div class="appointment-cancellation">
    <!-- 挂号信息 -->
    <div class="appointment-card">
      <div>
        <span>就诊人:</span>
        <span>{{ cardList[0].name }}</span>
      </div>
      <div>
        <span>科室:</span>
        <span>{{ $route.query.departMent }}</span>
      </div>
      <div>
        <span>医生:</span>
        <span>{{ $route.query.doctor }}</span>
      </div>
      <div>
        <span>就诊日期:</span>
        <span>{{ $route.query.diagnosisTime }}</span>
      </div>
      <div>
        <span>就诊时段:</span>
        <span>
          {{ shiftTime[$route.query.shift] }}
        </span>
      </div>
      <div>
        <span>挂号状态:</span>
        <span>{{ $route.query.flag == 0 ? '成功' : '已取消' }}</span>
      </div>
    </div>
    <div v-if="$route.query.diagnosisTime.split(' ')[0] >= today">
      <van-button
        class="button"
        type="primary"
        @click="cancel"
        v-show="$route.query.flag == 1 ? false : true"
        block
      >
        挂号取消
      </van-button>
    </div>
  </div>
</template>

<script>
import {
  jd2hisAppointmentCancel,
  updateLogFunTrade,
  refund,
} from '@/network/service'
// import { jd2hisAppointmentCancel } from '@/network/service'
import { times } from '@/common/utils'
import { mapState } from 'vuex'
export default {
  name: 'appointmentCancellation',
  data() {
    return {
      today: '',
      //号源对应时间
      shiftTime: {
        '1': '全天',
        '2': '06:00-12:00',
        '3': '14:30-17:00',
        '20': '08:00-08:30',
        '21': '08:30-09:00',
        '22': '09:00-09:30',
        '23': '09:30-10:00',
        '24': '10:00-10:30',
        '25': '10:30-11:00',
        '26': '11:00-11:30',
        '27': '11:30-12:00',
        '28': '14:30-15:00',
        '29': '15:00-15:30',
        '30': '15:30-16:00',
        '31': '16:00-16:30',
        '32': '16:30-17:00',
        '33': '14:00-14:30',
        '36': '17:00-17:30',
      },
    }
  },
  computed: {
    ...mapState(['seq', 'cardList']),
  },
  created() {
    this.today = times(false).split(' ')[0]
    console.log(this.$route.query)
  },
  methods: {
    // 挂号取消
    cancel() {
      this.$dialog
        .confirm({
          title: '',
          message: '是否取消挂号',
        })
        .then(async () => {
          //取消挂号
          let res = await jd2hisAppointmentCancel({
            bId: this.$route.query.bid,
            p_id: this.$route.query.registerSeq,
            card_no: this.cardList[0].cardNo,
            card_type: this.cardList[0].cardType,
            med_id: this.$store.state.med_id,
          })
          if (res.code == '0') {
            let resulte = await refund({
              seq: this.seq,
              urlString: this.$urlString,
              appKey: '17b1a89be880001',
              appSecret: '33cb874e10bb409a84f71d29f49ca017',
              mchNo: 'lffbyy',
              tradeNo: res.data.root.body.tradeNo
                ? res.data.root.body.tradeNo
                : res.data.root.body.bankno,
              refundReason: '取消挂号',
            })
            if (resulte.code == '0') {
              //更新流水号
              await updateLogFunTrade({
                seq: this.seq,
                finishFlag: '完成挂号取消',
                card_type: this.cardList[0].cardType,
                card_no: this.cardList[0].cardNo,
                idno: this.cardList[0].idNo,
                name: this.cardList[0].name,
              })
              this.$router.go(-1)
              this.$toast('取消成功')
            }
            this.$toast('取消成功')
          } else {
            this.$toast(res.msg)
          }
        })
        .catch(() => {})
    },
  },
}
</script>

<style lang="less" scoped>
.button {
  width: 80vw;
  margin: auto;
  margin-top: 2rem;
  background-color: @darkColor;
  border-radius: 2rem;
  border: none;
}
.appointment-cancellation {
  background-color: #f4f4f4;
  height: 100%;
  padding-top: 2rem;
  .appointment-card {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 1rem 0;
    padding-left: 1rem;
    border-radius: 1rem;
    background-color: rgba(254, 254, 254);
    width: 90vw;
    line-height: 2rem;
    div {
      display: flex;
      span:first-child {
        flex: 0.3;
        color: #8d8fa1;
      }
      span {
        flex: 0.6;
      }
    }
  }
}
</style>
